@import url("https://fonts.googleapis.com/css2?family=Anek+Latin&family=Kanit:wght@100&display=swap"); 

* {
  margin: 0; 
  padding: 0; 
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-weight: 1000;
}
.rain {
  background-image: url("/public/images/rain-png-transparent-9.png");
  height: 100vh; 
  background-size: cover;
  animation: rain .2s infinite; 
  opacity: 1;
}

@keyframes rain {
  to {
    background-position: 0 0;
  }
  from {
    background-position: 20% 100%;
  }
}

.App {
  min-height: 100vh;
  opacity: .95;
  transition: background-image 1s;
  padding: .5em;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#header {
  text-align: center;
  color: #fff;
}

.container {
  display: grid; 
  grid-template-columns: repeat(3, 1fr);
  background-color: rgba(2.4, 23.9, 29.8, 0.5);
  border-radius: 50px;
  padding: 1em 1em;
  gap: 1.5em;
  box-shadow: 2px 5px 9px 1px #302f2f;
  color: white;
  margin-top: 1em;
  width: 570px;
}

@media screen and (max-width: 440px) {
  .container {
    margin-left: 1.7em;
  }
}

.mood-selector {
  max-width: 100%;
}

.mood-btns {
  display: block;
  width: 7em;
  padding: .5em;
  font-size: 1.2em;;
 
}

.mood-btns:hover {
  background-color: #185465;
}

.clicked {
  background-color: #185465; 
  width: 7em;
  font-size: 1.2em;
  padding: .5em;
}

@media screen and (max-width: 700px) {
  .container {
    max-width: 85vw; 
    margin-left: 1em;
  }

  .mood-btns {
    font-size: 3.5vw;
    max-width: 20vw;
  }

  .clicked {
    max-width: 20vw; 
    font-size: 3.5vw;
  }

}

.btn {
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;  
}

button {
  background-color: #467F8F;
  color: #fff; 
  padding: .7em .7em;
  border: none;
  border-radius: 100px;
  transform: .2s;
  cursor: pointer;
}

button:hover {
  background-color: #185465; 
}

#spotify-btn {
  font-size: 2em;
}

.btn-hide {
  display: none;
}

.added-items {
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hyperlink {
  border-radius: 3px;
  border: none;
  height: 290px;
  max-width: 75vw;
  box-shadow: 2px 2px 15px 2px #444343;
  animation: fadeIn 1.5s ease-in;
}

@media screen and (max-width: 400px) {
  .hyperlink {
    max-width: 70vw;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.song-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em;
  gap: .3em;
  font-size: 1.5em;
}

img {
  max-width: 100%; 
  box-shadow: 2px 2px 15px 1px #444343;
}